<template>
    <el-card shadow="never">
        <el-row>
            <div class="date">维修日期：</div>
            <el-date-picker
                    v-model="capacityMaintenance"
                    type="datetimerange"
                    :clearable=false
                    value-format="yyyy-MM-dd HH:mm:ss"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
            <el-button type="primary" @click="recyclableBoxData" :loading="loading" class="btn">导出维修数据</el-button>
        </el-row>
    </el-card>
</template>

<script>
    import {betweenDays, yesterday} from "@/utils/common";

    export default {
        name: "MaintenanceDataReport",
        data() {
            return {
                capacityMaintenance: [yesterday() + ' 00:00:00', yesterday() + ' 23:59:59'],
                loading: false
            }
        },
        methods: {
            recyclableBoxData() {
                if (!this.capacityMaintenance|| this.capacityMaintenance.length != 2) {
                    return this.$message.error('请选择日期');
                }
                if (betweenDays(this.capacityMaintenance[0], this.capacityMaintenance[1]) > 31) {
                    return this.$message.error('日期范围不能超过1个月，请分批导出。');
                }

                this.loading = true;
                this.$axios.post('recyclableBox/maintenanceDataReport', {
                    beginTime: this.capacityMaintenance[0],
                    endTime: this.capacityMaintenance[1],
                }, {timeout: 180000}).then(response => {
                    this.loading = false;
                    let res = response.data;
                    if (res.code !== 0) {
                        return this.$message.error(res.message);
                    }

                    if (res.data.url) {
                        const link = document.createElement('a');
                        link.href = res.data.url;
                        document.body.appendChild(link);
                        link.click();
                        URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);
                    }
                }, error => {
                    this.loading = false;
                    return this.$message.error('导出失败，' + error.message);
                });
            }
        }
    }
</script>

<style scoped>
    .btn {
        margin-left: 40px;
    }

    .date {
        display: inline-block;
        width: 120px;
        text-align: right;
    }
</style>